var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"id":"modal-update-deposit-type","cancel-variant":"outline-secondary","ok-title":_vm.$t('Confirm'),"cancel-title":_vm.$t('Close'),"ok-variant":"success","centered":"","modal-class":"modal-success","title":_vm.$t('Update Deposit Type')},on:{"ok":_vm.confirm,"hidden":function($event){return _vm.resetModal()}}},[_c('validation-observer',{ref:"updateDepositTypeModal"},[_c('b-form',{attrs:{"method":"POST"},on:{"submit":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label-for":"id"}},[_c('label',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.$t("id")))]),_c('validation-provider',{attrs:{"name":"id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"id","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"disabled":""},model:{value:(_vm.depositType.id),callback:function ($$v) {_vm.$set(_vm.depositType, "id", $$v)},expression:"depositType.id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-for":"name"}},[_c('label',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.$t("Name")))]),_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.depositType.name),callback:function ($$v) {_vm.$set(_vm.depositType, "name", $$v)},expression:"depositType.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-for":"name_en"}},[_c('label',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.$t("English Name")))]),_c('validation-provider',{attrs:{"name":"name_en","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name_en","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.depositType.name_en),callback:function ($$v) {_vm.$set(_vm.depositType, "name_en", $$v)},expression:"depositType.name_en"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-for":"logo"}},[_c('label',{attrs:{"for":""}},[_vm._v(" "+_vm._s(_vm.$t("logo"))+" "),_c('span',{staticClass:"text-secondary"},[_vm._v("(Không > 10Mb)")])]),_c('validation-provider',{attrs:{"name":"logo","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"input-id":"logo","accept":"image/jpeg,image/png,image/gif,image/svg,image/jpg"},model:{value:(_vm.depositType.logo),callback:function ($$v) {_vm.$set(_vm.depositType, "logo", $$v)},expression:"depositType.logo"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('b-img',{staticClass:"mr-2 rounded",staticStyle:{"width":"150px"},attrs:{"src":("" + (_vm.depositType.logo ? _vm.depositType.showImg : '')),"fluid":""}})],1),_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.depositType.logo),expression:"depositType.logo"}],staticClass:"btn-sm btn-icon",attrs:{"variant":"outline-danger"},on:{"click":function($event){_vm.depositType.logo = null}}},[_c('feather-icon',{attrs:{"icon":"XIcon"}})],1),_c('b-form-group',{attrs:{"label-for":"status"}},[_c('label',{staticClass:"mb-1 d-block"},[_vm._v("Status")]),_c('b-form-radio',{attrs:{"name":"status","value":"1","inline":""},model:{value:(_vm.depositType.status),callback:function ($$v) {_vm.$set(_vm.depositType, "status", $$v)},expression:"depositType.status"}},[_vm._v(" Active ")]),_c('b-form-radio',{attrs:{"name":"status","value":"2","inline":""},model:{value:(_vm.depositType.status),callback:function ($$v) {_vm.$set(_vm.depositType, "status", $$v)},expression:"depositType.status"}},[_vm._v(" Inactive ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }